import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery

$(document).on('turbolinks:before-cache', function () {
  if ($(".js-slider").length) {
    $(".js-slider").each(function(index, item) {
      $(item).slick('unslick');
    });
  }
})

$(document).on('turbolinks:load', function(){
  if ($(".js-slider").length) {
    $(".js-slider").each(function () {
      $(this).slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      });
    });
  }

  $(window).on("load resize", function () {
    if ($(".js-fixed-height").length) {
      setTimeout(() => {
        $(".js-fixed-height").each(function () {
          $(this).height($(this).width());
        });
      }, 500);
    }

    if ($(".slick-slider").length) {
      setTimeout(() => {
        $(".slick-slider").each(function () {
          const $this = $(this);
          const fixedHeight = $this.find(".js-fixed-height").height();
          $this.find(".slick-arrow").css("top", fixedHeight / 2 - 20);
        });
      }, 500);
    }
  });

  if ($(".js-productSlider").length) {
    $(".js-productSlider").slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      arrows: true,
      responsive: [
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 6,
          },
        },
      ],
    });

    $(document).on("click", ".js-changeCurrentImageBtn", function () {
      const newSrc = $(this).find("img").attr("src");
      $(".curentImage").find("img").attr("src", newSrc);
    });
  }

  $(document).mouseup(function (e) {
    const container = $(".js-hide-when-click-outsite");
    const toggleContentBtn = $(".js-toggle-content");

    if (
      !container.is(e.target) &&
      container.has(e.target).length === 0 &&
      !toggleContentBtn.is(e.target) &&
      toggleContentBtn.has(e.target).length === 0
    ) {
      $(".opening").removeClass("opening");
    }
  });

  $(document).on("click", ".js-toggle-main-menu", function (e) {
    e.preventDefault();
    e.stopPropagation();

    const dataContent = $(".js-mainHeader");
    if ($(dataContent).hasClass("opening")) {
      $(".js-hide-when-click-outsite").removeClass("opening");
      $(".js-mainHeader").removeClass("opening");
      $(this).removeClass("opening");
    } else {
      $(".js-hide-when-click-outsite").removeClass("opening");
      dataContent.addClass("opening");
      $(this).addClass("opening");
    }
  });

  $(document).on("click", ".js-toggle-childNav", function (e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).find(".childNav").toggleClass("active");
  });

  // Check if current page is index page => will remove later
  if ($(".indexPage").length) {
    $("body").addClass("indexPage-controller");
  }
});

$(document).on("click", "[data-btn-view-more]", function () {
  let url = $(this).data("url");
  let currentPage = $(this).data("current-page");
  let totalPages = $(this).data("total-pages");
  let container = $(this).data("container");
  let currentId = $(this).data("current-id");

  $.ajax({
    url: `${window.location.origin}/${url}`,
    type: "GET",
    dataType: "json",
    data: {
      page: currentPage + 1,
      current_id: currentId,
    },
    success: function (data) {
      if (data.html) {
        $(container).append(data.html);
      }

      let $btnLoadMore = $(container)
        .closest(".container")
        .find("[data-btn-view-more]");
      if (data.current_page >= data.total_pages) {
        $btnLoadMore.hide();
      } else {
        $btnLoadMore.show();
        $btnLoadMore.data("total-pages", data.total_pages);
        $btnLoadMore.data("current-page", data.current_page);
      }
    },
    error: function (error) {
      console.error(error);
    },
  });
});
